import { Component, Input } from '@angular/core'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-video-skeleton-card',
  templateUrl: './ui-video-skeleton-card.component.html',
})
export class UiVideoSkeletonCardComponent {
  @Input() count = 1
}
