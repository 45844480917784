import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-logo-jobs-blue',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class AppLogoJobsBlueComponent {
  @Input() height: string = '64px' // String will allow for px or rem
  @Input() resizable: boolean = true // False will let the logo stay a static size, if needed. Example: App footer
  constructor() {}
}
