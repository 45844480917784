import { Component, EventEmitter, Input, Output } from '@angular/core'

import { IReference } from '../../model/interfaces'

@Component({
  selector: 'ui-reference-single-card-vertical',
  template: `
    <div style="e11-flex">
      <div class="e11-w-full">
        <h4 class="e11-float-left e11-mr-2 e11-text-skin-primary-accent">{{ reference.firstName }} {{ reference.lastName }}</h4>
        <a
          class="e11-flex e11-items-center e11-justify-center e11-cursor-pointer e11-float-left"
          (click)="viewDetail.emit(reference); editView.emit(true)"
        >
          <span class="material-icons-outlined e11-text-skin-light hover:e11-text-skin-primary e11-h-8 e11-w-8"> edit </span>
        </a>
        <a class="e11-flex e11-items-center e11-justify-center e11-cursor-pointer e11-float-left" (click)="deleteItem.emit(reference)">
          <span class="material-icons-outlined e11-text-skin-light hover:e11-text-skin-secondary e11-h-8 e11-w-8"> delete </span>
        </a>
        <div class="e11-clear-both"></div>
        <p>{{ reference.referenceType }}</p>
        <p>{{ reference.companyName }}</p>
        <p>{{ reference.referenceRelationship }}</p>
      </div>
    </div>
  `,
  styleUrls: ['./ui-reference-single-card-vertical.scss'],
})
export class UiReferenceSingleCardVerticalComponent {
  @Input()
  reference!: IReference

  @Output()
  viewDetail = new EventEmitter()

  @Output()
  editView = new EventEmitter()

  @Output()
  deleteItem = new EventEmitter()
}
