import { Injectable } from '@angular/core'
import { E11ToastService, ToastDismissType } from '@engineering11/ui-lib/e11-toast'
import { ViewportService, ViewPortSize } from '@engineering11/web-utilities'
import { Router } from '@angular/router'

export type ToastCTA = 'resendVerificationEmail'

/**
 * Paths to blacklist the verification email toast from appearing
 */
const VERIFICATION_EMAIL_TOAST_BLACKLIST = ['/actions/success/verifyEmail']

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private toastService: E11ToastService, private viewportService: ViewportService, private router: Router) {}

  /**
   * Returns blacklist routes where the verification email toast should not appear
   */
  protected verificationEmailToastBlacklist(): string[] {
    return VERIFICATION_EMAIL_TOAST_BLACKLIST
  }

  verificationEmailToast() {
    const currentRoute = this.router.url
    if (this.verificationEmailToastBlacklist().includes(currentRoute)) {
      return
    }

    const viewport = this.viewportService.getCurrentSize()
    const smallerSizes: ViewPortSize[] = ['xs', 'sm', 'md']
    const small = smallerSizes.includes(viewport)
    const cta: ToastCTA = 'resendVerificationEmail'
    // Todo: Translate these...
    this.toastService.popToastMessage({
      title: 'As a reminder...',
      bodyTitle: 'Verify your email now to claim your cnect account!',
      bodyContent: small ? '' : 'Please check your inbox for an email from us. If you do not see it, please check your junk and spam folders.',
      type: '',
      dismissType: ToastDismissType.User,
      dismissText: 'Later',
      cta,
      ctaText: small ? 'Resend Email' : 'Resend verification email',
      icon: 'mail_outline',
      dismissOnRouteChange: true,
      delay: 2000, // 2s gives time for maintenance page to rise to full z index and cover the toast
    })
  }

  closeToast() {
    this.toastService.closeToast()
  }
}
