export interface IEmploymentAuthorization {
  country: string
  visaSponsorshipRequired: boolean
  legalWorkAuthorizationType: Exclude<EmploymentAuthorizationType, EmploymentAuthorizationType.None>
  tempWorkAuthorizationExpiration?: IWorkAuthorizationExpiration
}

export enum EmploymentAuthorizationType {
  Permanent = 'permanent',
  Temporary = 'temporary',
  None = 'none',
}

export interface IWorkAuthorizationExpiration {
  month: number
  year: number
}
