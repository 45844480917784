import { Component, Inject, OnInit } from '@angular/core'
import { MAINTENANCE_TEMPLATE_KEY } from '@engineering11/config-web'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { E11Logger } from '@engineering11/web-api-error'
import { map } from 'rxjs'
import { ENVIRONMENT } from '../../constants'
import { IEnvironment } from '../../model/environment.model'
import { ConfigStore } from '../../store/config.store'

// Note: Currently need E11UtilModule to use `e11Template`

@Component({
  selector: 'lib-maintenance-page',
  template: `
    <sdk-config-maintenance-page>
      <ng-template e11Template="maintenance" *ngIf="isDefaultTenant">
        <ui-maintenance-page></ui-maintenance-page>
      </ng-template>
    </sdk-config-maintenance-page>
  `,
})
export class MaintenancePageComponent implements OnInit {
  isDefaultTenant = false // Default tenant gets a special custom version of the page for cnect
  MAINTENANCE_TEMPLATE_KEY = MAINTENANCE_TEMPLATE_KEY
  listenConfig$ = this.configStore.listenConfig$

  constructor(private configStore: ConfigStore, private logger: E11Logger, private appConfigService: AppConfigService) {}
  ngOnInit(): void {
    this.isDefaultTenant = this.appConfigService.config.isDefaultTenant
  }
}
