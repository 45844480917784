export enum CONFIGURATION_KEYS {
  CANDIDATE_DEFAULT_VIDEO_SCRIPT = 'candidate-default-video-script',

  // FEATURE FLAGS
  // CANDIDATE
  CANDIDATE_FEATURE_FLAG_CORE = 'candidate-feature-flag-core',
  CANDIDATE_FEATURE_FLAG_EDUCATION = 'candidate-feature-flag-education',
  CANDIDATE_FEATURE_FLAG_FIRST_IMPRESSION = 'candidate-feature-flag-first-impression',
  CANDIDATE_FEATURE_FLAG_PORTFOLIO = 'candidate-feature-flag-portfolio',
  CANDIDATE_FEATURE_FLAG_REFERENCES = 'candidate-feature-flag-references',
  CANDIDATE_FEATURE_FLAG_VIRTUAL_INTERVIEW = 'candidate-feature-flag-virtual-interview',
  CANDIDATE_FEATURE_FLAG_WORK_HISTORY = 'candidate-feature-flag-work-history',
  ON = 'on',
  OFF = 'off',
  COMING_SOON = 'coming-soon',
}
