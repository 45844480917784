import { Component, Input } from '@angular/core'

@Component({
  selector: 'quick-info',
  template: `
    <div class="e11-flex e11-gap-2 e11-mb-4 e11-p-4 e11-text-sm e11-bg-skin-warning/10 e11-border e11-rounded e11-border-skin-warning">
      <span class="material-icons-outlined md-24 e11-text-skin-warning">{{ icon }}</span>
      <p class="e11-mb-0">
        {{ text | translate }}
      </p>
    </div>
  `,
})
export class QuickInfoComponent {
  @Input() text: string = ''
  @Input() icon: string = 'info'
}
