<div class="profile-pic-container {{ size }}">
  <!-- We have a profile pic -->
  <div class="profile-pic {{ size }}" [style.backgroundImage]="'url(' + imageUrl + ')'" *ngIf="imageUrl"></div>

  <!-- We DO NOT have a profile pic -->
  <div class="profile-pic {{ size }}" *ngIf="!imageUrl">
    <span class="material-icons user-none"> person </span>
  </div>

  <!-- Edit the profile -->
  <div *ngIf="edit" class="camera" (click)="editPic()">
    <i class="fas fa-camera"></i>
  </div>
</div>
