<svg
  class=""
  [ngClass]="{ 'logo-svg-resizable': resizable }"
  style="height: {{ height }}"
  aria-label="return to cnect home"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 -8 360 86"
  xml:space="preserve"
>
  <g id="logo-jobs-white">
    <g>
      <path
        class="fill-white"
        d="M100.12,48.02c-3.29,4.26-7.89,6.4-13.89,6.4c-10.43,0-17.17-7.09-17.17-16.02c0-9.16,7.14-16.19,17.28-16.19
			c5.99,0,11.06,2.48,14.12,6.97l-6.4,3.51c-2.19-2.3-4.32-3.46-7.66-3.46c-5.88,0-9.62,3.98-9.62,9.28c0,5.19,3.63,8.87,9.28,8.87
			c3.4,0,6.11-1.15,8.01-3.51L100.12,48.02z"
      />
      <path
        class="fill-white"
        d="M113.89,26.13c2.94-2.48,5.65-3.92,9.05-3.92c5.7,0,10.49,3.92,10.49,10.49v20.92h-7.66V39.15
			c0-6.05-0.52-9.91-5.18-9.91c-2.13,0-3.98,1.04-5.19,2.94c-1.56,2.48-1.5,5.24-1.5,8.41v13.02h-7.66v-30.6h7.66V26.13z"
      />
      <path
        class="fill-white"
        d="M168.33,47.68c-3.28,4.67-7.61,6.74-14.06,6.74c-9.74,0-16.02-7.15-16.02-16.13c0-8.35,6.05-16.08,15.9-16.08
			c9.68,0,16.25,6.92,16.25,16.59c0,0.69-0.06,1.04-0.06,1.73h-24.66c0.75,4.55,4.21,7.09,8.58,7.09c3.28,0,5.47-0.92,7.61-3
			L168.33,47.68z M162.69,34.48c-1.04-3.57-4.32-5.76-8.41-5.76c-3.8,0-6.74,1.9-8.24,5.76H162.69z"
      />
      <path
        class="fill-white"
        d="M204.97,48.02c-3.29,4.26-7.89,6.4-13.89,6.4c-10.43,0-17.17-7.09-17.17-16.02c0-9.16,7.14-16.19,17.28-16.19
			c5.99,0,11.06,2.48,14.12,6.97l-6.4,3.51c-2.19-2.3-4.32-3.46-7.66-3.46c-5.88,0-9.62,3.98-9.62,9.28c0,5.19,3.63,8.87,9.28,8.87
			c3.4,0,6.11-1.15,8.01-3.51L204.97,48.02z"
      />
      <path class="fill-white" d="M213.15,11.72h7.66v11.29h4.55v6.57h-4.55v24.03h-7.66V29.59h-3.92v-6.57h3.92V11.72z" />
      <path
        class="fill-white"
        d="M236.95,49.98c0,2.42-1.9,4.44-4.38,4.44c-2.42,0-4.44-2.02-4.44-4.44c0-2.48,2.02-4.38,4.44-4.38
			C234.99,45.61,236.95,47.56,236.95,49.98z"
      />
      <path
        class="fill-white"
        d="M243.17,23.02h7.66v32.44c0,6.74-2.71,10.14-8.07,10.14c-2.02,0-3.98-0.58-5.76-1.15V58.4
			c0.92,0.46,2.07,0.86,3.17,0.86c2.25,0,3-1.38,3-4.09V23.02z M251.87,15.47c0,2.71-2.19,4.9-4.9,4.9c-2.82,0-4.9-2.48-4.9-5.3
			c0-2.65,2.19-4.67,4.84-4.67C249.68,10.4,251.87,12.7,251.87,15.47z"
      />
      <path
        class="fill-white"
        d="M287.82,38.29c0,8.87-6.86,16.13-16.19,16.13c-8.87,0-15.9-7.09-15.9-16.08c0-9.28,7.49-16.13,15.85-16.13
			C280.5,22.21,287.82,29.24,287.82,38.29z M263.45,38.29c0,5.19,3.28,8.87,8.24,8.87c4.72,0,8.41-3.46,8.41-8.82
			c0-5.19-3.4-8.87-8.41-8.87C266.91,29.47,263.45,33.22,263.45,38.29z"
      />
      <path
        class="fill-white"
        d="M300.55,53.61h-7.66V11.21h7.66v15.15c2.77-2.77,5.93-4.15,9.62-4.15c7.89,0,14.46,6.57,14.46,16.13
			c0,9.39-6.86,16.08-14.64,16.08c-3.86,0-6.68-1.38-9.45-4.03V53.61z M300.2,38.23c0,5.53,3.69,9.16,8.41,9.16
			c4.78,0,8.3-3.86,8.3-9.1c0-5.01-3.23-9.05-8.3-9.05C303.72,29.24,300.2,32.87,300.2,38.23z"
      />
      <path
        class="fill-white"
        d="M335.87,40.36c-4.9-2.48-7.38-5.47-7.38-9.33c0-4.78,4.03-8.82,9.91-8.82c3.98,0,7.61,1.79,10.08,5.07
			l-4.72,4.78c-1.73-1.73-3.46-2.82-5.3-2.82c-1.56,0-2.77,0.63-2.77,1.84c0,1.15,1.09,1.67,2.77,2.54l2.82,1.44
			c5.07,2.59,7.78,5.24,7.78,9.79c0,5.47-4.32,9.57-11.01,9.57c-4.44,0-8.07-1.79-10.72-5.24l4.72-5.19
			c1.79,2.07,4.38,3.57,6.4,3.57c1.9,0,3.4-1.1,3.4-2.48c0-1.33-1.27-2.31-3.4-3.4L335.87,40.36z"
      />
    </g>
    <path
      class="fill-secondary"
      d="M62.85,8.86c-2.81-2.81-6.68-4.16-10.84-3.87c-3.97,0.29-7.75,2.03-10.75,5.04l-9.39,9.49
		c-0.19,0.19-0.39,0.39-0.48,0.68c3.2-0.48,6.49-0.19,9.49,0.87l5.81-5.81c1.65-1.65,3.78-2.71,6-2.91c2.03-0.1,3.78,0.48,5.03,1.74
		c1.26,1.26,1.84,3,1.74,5.04c-0.19,2.23-1.16,4.36-2.91,6L45.52,36.07c-1.65,1.65-3.78,2.71-6,2.9c-2.03,0.1-3.78-0.48-5.03-1.74
		c-1.45-1.45-3.78-1.45-5.23,0c-1.45,1.45-1.45,3.78,0,5.23c2.52,2.52,5.91,3.87,9.68,3.87c0.39,0,0.77,0,1.16,0
		c3.97-0.29,7.75-2.03,10.75-5.04l10.84-10.84c2.91-2.91,4.74-6.78,5.04-10.75C67.02,15.55,65.66,11.67,62.85,8.86z"
    />
    <path
      class="fill-primary-accent"
      d="M10.47,61.44c2.81,2.81,6.68,4.16,10.84,3.78c3.97-0.29,7.75-2.13,10.75-5.04l9.39-9.49
		c0.19-0.19,0.39-0.48,0.48-0.68c-3.2,0.48-6.49,0.29-9.49-0.87l-5.71,5.81c-1.65,1.74-3.78,2.71-6,2.9
		c-2.03,0.19-3.78-0.48-5.04-1.65c-1.26-1.26-1.84-3-1.74-5.04c0.1-2.23,1.16-4.36,2.81-6l10.84-10.94c1.65-1.74,3.78-2.71,6-2.9
		c2.03-0.19,3.78,0.48,5.04,1.65c1.45,1.45,3.78,1.45,5.23,0c1.45-1.45,1.45-3.78,0-5.23c-2.52-2.52-6-3.87-9.68-3.87
		c-0.39,0-0.77,0-1.16,0c-3.97,0.29-7.75,2.13-10.75,5.04L11.53,39.95c-2.91,3-4.74,6.78-4.94,10.75
		C6.31,54.86,7.66,58.73,10.47,61.44z"
    />
  </g>
</svg>
