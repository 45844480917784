import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { IEmployerJobPostReactivatedNotification } from '../../model/app-notification.model'

@Injectable({ providedIn: 'root' })
export class EmployerJobPostReactivatedNotificationHandler
  implements INotificationHandler<IEmployerJobPostReactivatedNotification, INotificationView>
{
  notificationType = 'employer_job_post_reactivated'
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}

  async onClick(notification: IEmployerJobPostReactivatedNotification): Promise<boolean> {
    if (!notification.viewed) {
      await this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    const contentId = notification.contentId ?? notification.jobPostId.split('_')[0]
    return this.router.navigate(['/jobs/post/', contentId])
  }
}
