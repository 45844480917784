<svg
  class=""
  [ngClass]="{ 'logo-svg-resizable': resizable }"
  style="height: {{ height }}"
  aria-label="return to cnect home"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 355.61 162.1"
  xml:space="preserve"
>
  <g id="logo-blue">
    <g>
      <path
        class="fill-primary-accent"
        d="M135,68.53c2.13,0,4,0.33,5.57,0.98c1.57,0.65,3.18,1.84,4.81,3.54l0.31,0.32l9.93-5.46l-0.36-0.53
      c-2.21-3.2-5.08-5.7-8.55-7.44c-3.46-1.73-7.42-2.61-11.75-2.61c-4.68,0-8.98,1.03-12.78,3.07c-3.8,2.04-6.81,4.89-8.93,8.47
      c-2.12,3.58-3.2,7.54-3.2,11.79c0,6.13,2.21,11.55,6.58,16.12c4.37,4.58,10.49,6.9,18.17,6.9c8.55,0,15.27-3.1,19.97-9.22
      l0.37-0.48l-9.45-6.47l-0.34,0.41c-2.56,3.14-6.2,4.73-10.82,4.73c-3.73,0-6.78-1.11-9.05-3.31c-2.27-2.19-3.42-5.06-3.42-8.54
      c0-3.58,1.2-6.55,3.56-8.83C127.98,69.69,131.14,68.53,135,68.53z"
      ></path>
      <path
        class="fill-primary-accent"
        d="M188.01,57.34c-2.14,0-4.26,0.42-6.31,1.25c-1.77,0.72-3.73,1.93-5.85,3.62v-3.76h-11.94v44.13h11.94V84.76
      c0-4.41,0.15-7.3,0.46-8.59c0.57-2.45,1.61-4.39,3.08-5.77c1.46-1.37,3.17-2.03,5.22-2.03c1.54,0,2.85,0.4,3.88,1.2
      c1.04,0.8,1.78,1.99,2.2,3.54c0.44,1.63,0.67,5.01,0.67,10.07v19.4h11.82V73.6c0-5.08-1.32-8.99-3.92-11.63
      C196.2,58.9,192.41,57.34,188.01,57.34z"
      ></path>
      <path
        class="fill-primary-accent"
        d="M233.26,57.34c-6.5,0-11.99,2.25-16.31,6.67c-4.32,4.43-6.5,10.06-6.5,16.74c0,6.53,2.2,12.04,6.53,16.39
      c4.33,4.35,10.01,6.55,16.88,6.55c4.41,0,8.29-0.8,11.51-2.37c3.23-1.58,6.04-4.04,8.36-7.32l0.4-0.56l-10.07-4.73l-0.29,0.28
      c-2.77,2.7-6.13,4.06-9.99,4.06c-3.22,0-5.89-0.87-7.95-2.57c-1.89-1.57-3.13-3.68-3.68-6.26h34.51l0.05-2.63
      c0-7.24-2.2-13.15-6.52-17.57C245.85,59.58,240.16,57.34,233.26,57.34z M222.84,74.56c1.06-2.13,2.2-3.65,3.39-4.53
      c2.16-1.6,4.7-2.41,7.57-2.41c2.63,0,4.98,0.72,6.96,2.14c1.76,1.26,3.02,2.87,3.75,4.8H222.84z"
      ></path>
      <path
        class="fill-primary-accent"
        d="M287.12,68.53c2.13,0,4,0.33,5.57,0.98c1.57,0.65,3.18,1.84,4.81,3.54l0.31,0.32l9.93-5.46l-0.36-0.53
      c-2.21-3.2-5.08-5.7-8.55-7.44c-3.46-1.73-7.42-2.61-11.75-2.61c-4.68,0-8.98,1.03-12.78,3.07c-3.8,2.04-6.81,4.89-8.93,8.47
      c-2.12,3.58-3.2,7.54-3.2,11.79c0,6.13,2.21,11.55,6.58,16.12c4.37,4.58,10.49,6.9,18.17,6.9c8.55,0,15.27-3.1,19.97-9.22
      l0.37-0.48l-9.45-6.47l-0.34,0.41c-2.56,3.14-6.2,4.73-10.82,4.73c-3.73,0-6.78-1.11-9.05-3.31c-2.27-2.19-3.42-5.06-3.42-8.54
      c0-3.58,1.2-6.55,3.56-8.83C280.1,69.69,283.26,68.53,287.12,68.53z"
      ></path>
      <polygon
        class="fill-primary-accent"
        points="329.27,58.44 329.27,42.6 317.33,42.6 317.33,58.44 311.8,58.44 311.8,68.88 317.33,68.88 
      317.33,102.58 329.27,102.58 329.27,68.88 335.67,68.88 335.67,58.44"
      ></polygon>
    </g>
    <path
      class="fill-secondary"
      d="M101.98,37.78c-4.06-4.06-9.67-6.03-15.7-5.61c-5.75,0.42-11.21,2.94-15.56,7.29L57.12,53.2
      c-0.28,0.28-0.56,0.56-0.7,0.98c4.63-0.7,9.39-0.28,13.74,1.26l8.41-8.41c2.38-2.38,5.47-3.92,8.69-4.21
      c2.94-0.14,5.47,0.7,7.29,2.52c1.82,1.82,2.66,4.35,2.52,7.29c-0.28,3.22-1.68,6.31-4.21,8.69L76.89,77.17
      c-2.38,2.38-5.47,3.92-8.69,4.2c-2.94,0.14-5.47-0.7-7.29-2.52c-2.1-2.1-5.47-2.1-7.57,0c-2.1,2.1-2.1,5.47,0,7.57
      c3.64,3.64,8.55,5.61,14.02,5.61c0.56,0,1.12,0,1.68,0c5.75-0.42,11.21-2.94,15.56-7.29l15.7-15.7c4.21-4.21,6.87-9.81,7.29-15.56
      C108,47.46,106.04,41.85,101.98,37.78z"
    ></path>
    <path
      class="fill-primary-accent"
      d="M26.15,113.89c4.06,4.06,9.67,6.03,15.7,5.47c5.75-0.42,11.21-3.08,15.56-7.29L71,98.34
      c0.28-0.28,0.56-0.7,0.7-0.98c-4.63,0.7-9.39,0.42-13.74-1.26l-8.27,8.41c-2.38,2.52-5.47,3.92-8.69,4.2
      c-2.94,0.28-5.47-0.7-7.29-2.38c-1.82-1.82-2.66-4.35-2.52-7.29c0.14-3.22,1.68-6.31,4.06-8.69l15.7-15.84
      c2.38-2.52,5.47-3.92,8.69-4.2c2.94-0.28,5.47,0.7,7.29,2.38c2.1,2.1,5.47,2.1,7.57,0c2.1-2.1,2.1-5.47,0-7.57
      c-3.64-3.64-8.69-5.61-14.02-5.61c-0.56,0-1.12,0-1.68,0c-5.75,0.42-11.21,3.08-15.56,7.29L27.69,82.78
      c-4.21,4.34-6.87,9.81-7.15,15.56C20.12,104.36,22.08,109.97,26.15,113.89z"
    ></path>
  </g>
</svg>
