export enum ProfileSection {
  firstImpression = 'firstImpression',
  summary = 'summary',
  languages = 'languages',
  workHistory = 'workHistory',
  education = 'education',
  militaryAffiliation = 'militaryAffiliation',
  skills = 'skills',
  certifications = 'certifications',
  portfolio = 'portfolio',
  references = 'references',
  socialMedia = 'socialMedia',
}

export const ProfileSectionIconsMap: Record<ProfileSection, string> = {
  [ProfileSection.firstImpression]: 'play_circle',
  [ProfileSection.summary]: 'subject',
  [ProfileSection.skills]: 'interests',
  [ProfileSection.certifications]: 'badge',
  [ProfileSection.education]: 'school',
  [ProfileSection.workHistory]: 'work_history',
  [ProfileSection.militaryAffiliation]: 'military_tech',
  [ProfileSection.references]: 'groups',
  [ProfileSection.socialMedia]: 'remember_me',
  [ProfileSection.portfolio]: 'folder_shared',
  [ProfileSection.languages]: 'language',
}

export const ProfileSectionNameMappings: Record<ProfileSection, string> = {
  [ProfileSection.firstImpression]: 'First Impression',
  [ProfileSection.summary]: 'Summary',
  [ProfileSection.languages]: 'Languages',
  [ProfileSection.workHistory]: 'Work History',
  [ProfileSection.education]: 'Education',
  [ProfileSection.militaryAffiliation]: 'Military Affiliations',
  [ProfileSection.skills]: 'Skills',
  [ProfileSection.certifications]: 'Certifications',
  [ProfileSection.portfolio]: 'Portfolio',
  [ProfileSection.references]: 'References',
  [ProfileSection.socialMedia]: 'Social Media',
}
