import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'requested-update-message',
  template: `
    <div *ngIf="message" class="e11-flex e11-w-full e11-p-2 e11-bg-skin-purple/10 e11-border e11-border-skin-purple e11-rounded">
      <div class="e11-mr-2">
        <span class="material-icons-outlined e11-text-skin-purple">quiz</span>
      </div>
      <div class="e11-flex e11-grow">
        <div class="e11-w-full">
          <div class="e11-w-full e11-font-bold">
            {{ 'More information requested ' | translate }}
          </div>
          <div class="e11-w-full e11-text-sm">
            <span class="e11-font-bold e11-mr-1">{{ 'Message: ' | translate }}</span> {{ message }}
          </div>
        </div>
      </div>
    </div>
  `,
})
export class RequestedUpdateMessageComponent implements OnInit {
  @Input() message?: string

  constructor() {}

  ngOnInit(): void {}
}
