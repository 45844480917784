import { Component } from '@angular/core'

@Component({
  selector: 'app-logo-cnect-boost',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class AppLogoCnectBoostComponent {
  constructor() {}
}
