import { Pipe, PipeTransform } from '@angular/core'
import { PortfolioUiType, portfolioUiTypeIsFile } from '../model/portfolio.model'

@Pipe({
  name: 'portfolioItemTypeIsFile',
})
export class PortfolioItemTypeIsFilePipe implements PipeTransform {
  transform(itemType: PortfolioUiType): boolean {
    return portfolioUiTypeIsFile(itemType)
  }
}
