import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IClientConfig } from '../model/client-config.model'
import { ClientConfigRepository } from '../repository/client-config.repository'

@Injectable({ providedIn: 'root' })
export class ClientConfigService {
  private featureFlags: any
  constructor(private repository: ClientConfigRepository) {}

  async get(key: string): Promise<IClientConfig> {
    return new Promise((resolve, reject) => {
      this.repository.get(key).subscribe(item => {
        if (item === undefined) {
          reject(`Config does not exist for ${key}`)
        }
        resolve(item!)
      })
    })
  }

  async getAll(): Promise<IClientConfig[]> {
    return new Promise((resolve, reject) => {
      this.repository.getAll().subscribe(all => {
        resolve(all)
      })
    })
  }

  getAllValueChanges(): Observable<IClientConfig[]> {
    return this.repository.getAllValueChanges()
  }

  isFeatureEnabled(feature: string) {
    return this.featureFlags.find((x: any) => x.id == feature).value
  }
}
