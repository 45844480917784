export interface ErrorHandlingConfig {
  USER_NAME_MIN_LENGTH: number
  USER_NAME_MAX_LENGTH: number
  PASSWORD_MIN_LENGTH: number
  PASSWORD_MAX_LENGTH: number
  EMAIL_MIN_LENGTH: number
  EMAIL_MAX_LENGTH: number
  FIRST_NAME_MIN_LENGTH: number
  FIRST_NAME_MAX_LENGTH: number
  LAST_NAME_MIN_LENGTH: number
  LAST_NAME_MAX_LENGTH: number
  COMPANY_NAME_MIN_LENGTH: number
  COMPANY_NAME_MAX_LENGTH: number
  PHONE_MIN_LENGTH: number
  PHONE_MAX_LENGTH: number
  ADDRESS_MIN_LENGTH: number
  ADDRESS_MAX_LENGTH: number
  STATE_REQUIRED_LENGTH: number
  ZIP_MIN_LENGTH: number
  ZIP_MAX_LENGTH: number
  COUNTRY_REQUIRED_LENGTH: number
}
