import { ICustomerLocation } from '@engineering11/customer-web'
import { IConversationVM } from '@engineering11/messaging-web'

export interface IApplicationConversationMetadata {
  jobPostName: string
  companyName: string
  jobPostId: string
  jobPostApplicationId: string
  candidateId: string
  companyId: string
  companyLogoUrl?: string | null
  location?: ICustomerLocation
}

export interface ICandidatePoolConversationMetadata {
  companyName: string
  candidateId: string
  candidatePoolId: string
  companyId: string
  companyLogoUrl?: string | null
}

export type IApplicationConversationCreateMetadata = Pick<IApplicationConversationMetadata, 'jobPostId' | 'jobPostApplicationId' | 'candidateId'>

export type ICandidatePoolConversationCreateMetadata = Pick<ICandidatePoolConversationMetadata, 'candidateId' | 'companyId' | 'candidatePoolId'>

export type IApplicationConversationVM = IConversationVM<IApplicationConversationMetadata>

export type IEmployerConversationVM = IConversationVM<IApplicationConversationMetadata>

export type ICandidatePoolVM = IConversationVM<ICandidatePoolConversationMetadata>
