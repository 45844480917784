import { Injectable } from '@angular/core'
import { BaseConversationConfigProvider, IConversationTypeConfig } from '@engineering11/messaging-web'
import { ConversationTypes } from '../../constants'

@Injectable({ providedIn: 'root' })
export class SocialDmConfigProvider extends BaseConversationConfigProvider {
  conversationType = ConversationTypes.SocialDm

  getConfig(): IConversationTypeConfig {
    return {
      canEditOwnMessages: true,
      canDeleteOwnMessages: true,
      canReactOnMessages: true,
      canDeleteConversation: true,
    }
  }
}
