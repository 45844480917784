import { Injectable } from '@angular/core'
import { IEmployer } from '../model/employer.model'
import { EmployerRepository } from '../repository/employer.repository'
import { Store } from '@ngrx/store'
import { getCustomerKey, getCustomerKey$ } from '../store/user/user.selectors'
import { isNotNil } from '@engineering11/utility'
import { filter, switchMap } from 'rxjs'
import { AtLeast, HasId, Indexable } from '@engineering11/types'
import { ValueOrFieldValue } from '@engineering11/web-api-firebase'

@Injectable({ providedIn: 'root' })
export class EmployerService {
  constructor(private repository: EmployerRepository, private store: Store) {}

  get(customerKey: string) {
    return this.repository.get(customerKey)
  }

  add(model: IEmployer) {
    model.id = model.id
    return this.repository.add(model)
  }

  update(model: AtLeast<Indexable<ValueOrFieldValue<IEmployer> & HasId>, 'id'>) {
    return this.repository.update(model)
  }

  set(model: IEmployer) {
    return this.repository.set(model)
  }

  delete(customerKey: string) {
    return this.repository.delete(customerKey)
  }
}
