<div class="layout-app">
  <app-header [(mobileDrawerState)]="mobileDrawerState"></app-header>
  <div class="layout-main e11-px-2 md:e11-px-6">
    <div class="layout-nav-left">
      <e11-nav-left [navigationList]="navigationList" [mobileTabs]="mobileTabs" [(mobileSideDrawerToggled)]="mobileDrawerState"></e11-nav-left>
    </div>
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>
