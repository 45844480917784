<svg
  class="logo-promo-cnect-boost"
  width="100%"
  viewBox="0 0 120 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="Employer---Promotions" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Promotions---Widget" transform="translate(-1070.000000, -376.000000)" fill-rule="nonzero">
      <g id="Promotions" transform="translate(1040.000000, 325.000000)">
        <g id="Boost-logo" transform="translate(30.000000, 51.000000)">
          <path
            d="M19.8030897,18 L19.7836994,18 L11.4458656,17.9241336 C10.5511412,17.915704 9.75336841,17.3593506 9.42373312,16.5163909 L8.49576822,14.1392445 L11.2713528,14.1645333 L11.72287,15.3165782 L19.2407707,15.384015 L16.7560408,9.23040899 L9.34340183,9.23040899 L9.49575427,9.62098033 L9.50683445,9.65188885 L10.2381262,11.5429285 L7.44592137,11.5176397 L6.05812909,7.92663128 L5.08861353,5.41742117 L4.08031734,2.80986575 L3,0 L4.4265729,0 C5.24373602,0 5.9777978,0.508585701 6.27419256,1.27848892 L6.86698207,2.80986575 L7.87527826,5.41742117 L8.10519195,6.01311271 L8.357266,6.6621917 C8.49853826,6.63409304 8.64258058,6.61723384 8.79216298,6.61723384 L17.0081149,6.61723384 C17.9000692,6.61723384 18.7033821,7.1623478 19.0413275,7.99968779 L21.8335323,14.9288167 C22.4263218,16.3955667 21.3626248,18 19.8030897,18 Z"
            id="Path"
            fill="#0480D0"
          ></path>
          <g id="Group" transform="translate(0.000000, 3.000000)" fill="#DB208F">
            <path
              d="M12.9263182,1.33662633 C12.575362,0.527043714 11.7411221,0 10.814828,0 L6.56883421,0 L7.61594921,2.52111633 L10.555926,2.52111633 L10.7716777,3.0155594 L13.6541206,3.0155594 L12.9263182,1.33662633 Z"
              id="Path"
            ></path>
            <path
              d="M13.7202845,11 C13.7145311,11 13.705901,11 13.7001477,11 L11.9079701,10.9864164 L11.1456473,10.980983 L8.26320437,10.9565325 L7.50088163,10.951099 L5.04418873,10.9293653 C4.115018,10.9212151 3.2865314,10.3833045 2.94420534,9.56828847 L0.162446517,2.94764139 C-0.430151536,1.5349469 0.671620572,0 2.28256674,0 L3.68063787,0 L4.72775288,2.52111633 L2.85790465,2.52111633 L5.328981,8.40553223 L6.43650648,8.41639911 L7.17293902,8.42183255 L10.0726421,8.44628303 L10.0812722,8.44628303 L10.8435949,8.45171647 L13.1334398,8.47073351 L12.4401576,6.87601877 L15.3254773,6.87601877 L15.8260213,8.02790813 C16.4445095,9.44603606 15.336984,11 13.7202845,11 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group" transform="translate(23.000000, 3.000000)">
            <g fill="#19365E">
              <path
                d="M5.2710367,5.5184136 C5.72059207,5.5184136 6.11676274,5.58923513 6.4455001,5.72804533 C6.77704719,5.86685552 7.11702343,6.11898017 7.4598094,6.4815864 L7.52443299,6.54957507 L9.62048489,5.38810198 L9.54462243,5.27478754 C9.07820873,4.59490085 8.47130898,4.06232295 7.74078151,3.69121813 C7.01025403,3.32294618 6.17576688,3.13597734 5.25979782,3.13597734 C4.270776,3.13597734 3.3632361,3.35694051 2.5624656,3.79036827 C1.75888538,4.22379603 1.12669814,4.83002833 0.677142774,5.59206799 C0.227587405,6.35410765 0,7.19830028 0,8.101983 C0,9.40509915 0.466413695,10.5609065 1.3880022,11.5325779 C2.30959071,12.5070822 3.60206239,13 5.22327144,13 C7.02711236,13 8.44602149,12.3399433 9.43785303,11.0396601 L9.51652522,10.9376771 L7.52162327,9.56090652 L7.45138024,9.64872521 C6.9119138,10.3172805 6.14205023,10.6543909 5.16707702,10.6543909 C4.38035513,10.6543909 3.736929,10.4164306 3.2564667,9.9490085 C2.7760044,9.48441926 2.53436839,8.87252125 2.53436839,8.13314448 C2.53436839,7.37110482 2.78724329,6.73937677 3.28456391,6.25212465 C3.78750398,5.76487252 4.45340787,5.5184136 5.2710367,5.5184136 Z"
                id="Path"
              ></path>
              <path
                d="M16.4593459,3.13597734 C16.0069809,3.13597734 15.5602352,3.2266289 15.1275382,3.40226629 C14.7538453,3.55524079 14.3408163,3.81303116 13.8940706,4.17280453 L13.8940706,3.37110482 L11.3737508,3.37110482 L11.3737508,12.7620397 L13.8940706,12.7620397 L13.8940706,8.97167139 C13.8940706,8.03399433 13.9277873,7.41926346 13.9896011,7.14447592 C14.1104191,6.62322946 14.3295774,6.20963173 14.6414564,5.91784703 C14.9505257,5.62606232 15.31017,5.48441926 15.7428671,5.48441926 C16.0687947,5.48441926 16.3441474,5.5694051 16.5633056,5.73937677 C16.7824639,5.90934844 16.9369985,6.16147309 17.0269096,6.49291785 C17.1196304,6.83852691 17.1673956,7.56090652 17.1673956,8.63456091 L17.1673956,12.7620397 L19.6624279,12.7620397 L19.6624279,6.59773371 C19.6624279,5.51558074 19.3842656,4.68271955 18.83637,4.12464589 C18.1873244,3.4674221 17.3865539,3.13597734 16.4593459,3.13597734 Z"
                id="Path"
              ></path>
              <path
                d="M26.0067781,3.13597734 C24.6328245,3.13597734 23.4752194,3.61473088 22.5648698,4.55524079 C21.6545202,5.49575071 21.1937259,6.69405099 21.1937259,8.11614731 C21.1937259,9.50424929 21.6573299,10.6798867 22.5704892,11.6033994 C23.4836486,12.529745 24.6833995,12.9971671 26.1332155,12.9971671 C27.0660429,12.9971671 27.880862,12.8271955 28.5636243,12.4929178 C29.2463865,12.1586402 29.8392376,11.6345609 30.3281291,10.9348442 L30.4124207,10.815864 L28.2882716,9.8101983 L28.2264577,9.86968839 C27.6420357,10.4447592 26.9311763,10.733711 26.1191669,10.733711 C25.4392144,10.733711 24.8772702,10.5495751 24.4417635,10.1869688 C24.0427831,9.85269122 23.781479,9.40509915 23.6634707,8.85269122 L30.9490774,8.85269122 L30.9603163,8.2917847 C30.9603163,6.75354108 30.4967123,5.49575071 29.583553,4.55240793 C28.6647742,3.61473088 27.4650233,3.13597734 26.0067781,3.13597734 Z M23.8095762,6.80169972 C24.0315442,6.34844193 24.2731802,6.02549575 24.5232454,5.83852691 C24.9784202,5.49858357 25.5150769,5.32577904 26.1219767,5.32577904 C26.6783014,5.32577904 27.1728123,5.47875354 27.5914608,5.78186969 C27.9623439,6.0509915 28.2292674,6.39376771 28.3809924,6.80169972 L23.8095762,6.80169972 Z"
                id="Shape"
              ></path>
              <path
                d="M37.3749095,5.5184136 C37.8244648,5.5184136 38.2206355,5.58923513 38.5521826,5.72804533 C38.8837297,5.86685552 39.2237059,6.11898017 39.5664919,6.4815864 L39.6311155,6.54957507 L41.7271674,5.38810198 L41.6513049,5.27478754 C41.1848912,4.59490085 40.5779915,4.06232295 39.847464,3.69121813 C39.1169365,3.32294618 38.2824494,3.13597734 37.3664803,3.13597734 C36.3774585,3.13597734 35.4699186,3.35694051 34.6691481,3.79036827 C33.8655679,4.22379603 33.2333806,4.83002833 32.7838253,5.59206799 C32.3342699,6.35410765 32.1066825,7.19830028 32.1066825,8.101983 C32.1066825,9.40509915 32.5730962,10.5609065 33.4946847,11.5325779 C34.4162732,12.5070822 35.7087449,13 37.3299539,13 C39.1337949,13 40.552704,12.3399433 41.5445355,11.0396601 L41.6232077,10.9376771 L39.6283058,9.56090652 L39.5580627,9.64872521 C39.0185963,10.3172805 38.2487327,10.6543909 37.2737595,10.6543909 C36.4870376,10.6543909 35.8436115,10.4164306 35.3631492,9.9490085 C34.8826869,9.48441926 34.6410509,8.87252125 34.6410509,8.13314448 C34.6410509,7.37110482 34.8939258,6.73937677 35.3912464,6.25212465 C35.8941865,5.76487252 36.5600904,5.5184136 37.3749095,5.5184136 Z"
                id="Path"
              ></path>
              <polygon
                id="Path"
                points="46.2732961 3.37110482 46.2732961 0 43.7529763 0 43.7529763 3.37110482 42.5841323 3.37110482 42.5841323 5.59206799 43.7529763 5.59206799 43.7529763 12.7620397 46.2732961 12.7620397 46.2732961 5.59206799 47.6219622 5.59206799 47.6219622 3.37110482"
              ></polygon>
            </g>
            <g transform="translate(52.710367, 0.000000)" fill="#0480D0">
              <path
                d="M1.15198563,12.5807365 L0,12.5807365 L0,0 L1.15198563,0 L1.15198563,5.05949008 C2.135388,3.86402266 3.35480694,3.26628895 4.76247719,3.26628895 C7.38956638,3.26628895 9.39008777,5.47025496 9.39008777,8.0509915 C9.39008777,10.6147309 7.35584972,12.8186969 4.72876054,12.8186969 C3.27051531,12.8186969 2.0679547,12.1699717 1.15198563,10.9036827 L1.15198563,12.5807365 Z M8.20157576,8.01699717 C8.20157576,6.0509915 6.6590389,4.32577904 4.64165918,4.32577904 C2.79286273,4.32577904 1.06488428,5.71104816 1.06488428,7.98300283 C1.06488428,10.0849858 2.60742114,11.6912181 4.64165918,11.6912181 C6.72647221,11.6912181 8.20157576,9.96600567 8.20157576,8.01699717 Z"
                id="Shape"
              ></path>
              <path
                d="M20.4407207,8.06798867 C20.4407207,10.6487252 18.4570576,12.8186969 15.7456768,12.8186969 C13.034296,12.8186969 11.0506329,10.6487252 11.0506329,8.06798867 C11.0506329,5.47025496 13.0511543,3.26628895 15.7456768,3.26628895 C18.4401993,3.26628895 20.4407207,5.47025496 20.4407207,8.06798867 Z M12.2363352,8.08498584 C12.2363352,10.0679887 13.7114388,11.6912181 15.7456768,11.6912181 C17.7630565,11.6912181 19.2550184,10.0679887 19.2550184,8.08498584 C19.2550184,6.06798867 17.6619066,4.39376771 15.7456768,4.39376771 C13.8125887,4.39376771 12.2363352,6.06798867 12.2363352,8.08498584 Z"
                id="Shape"
              ></path>
              <path
                d="M31.5419286,8.06798867 C31.5419286,10.6487252 29.5582655,12.8186969 26.8468847,12.8186969 C24.1355039,12.8186969 22.1518408,10.6487252 22.1518408,8.06798867 C22.1518408,5.47025496 24.1523622,3.26628895 26.8468847,3.26628895 C29.5414072,3.26628895 31.5419286,5.47025496 31.5419286,8.06798867 Z M23.3403528,8.08498584 C23.3403528,10.0679887 24.8154564,11.6912181 26.8496944,11.6912181 C28.8670741,11.6912181 30.359036,10.0679887 30.359036,8.08498584 C30.359036,6.06798867 28.7659242,4.39376771 26.8496944,4.39376771 C24.9166063,4.39376771 23.3403528,6.06798867 23.3403528,8.08498584 Z"
                id="Shape"
              ></path>
              <path
                d="M33.5593083,10.6827195 C34.1353011,11.3314448 34.7618689,11.6912181 35.4418214,11.6912181 C36.3240738,11.6912181 37.1023665,11.0424929 37.1023665,10.1869688 C37.1023665,9.6572238 36.7961069,9.16147309 36.3577905,8.92351275 C34.2898358,7.76203966 33.2390001,7.26628895 33.2390001,5.67705382 C33.2390001,4.2407932 34.3404107,3.26628895 35.7143643,3.26628895 C36.6977667,3.26628895 37.4423428,3.74504249 38.2065869,4.49575071 L37.4620108,5.26345609 C36.9197347,4.73371105 36.3774585,4.39093484 35.6497408,4.39093484 C34.9051647,4.39093484 34.2926455,4.92067989 34.2926455,5.60339943 C34.2926455,6.06515581 34.5820468,6.57790368 34.9866466,6.81586402 C37.0546013,8.02832861 38.2065869,8.49008499 38.2065869,10.1303116 C38.2065869,11.5495751 37.1220346,12.8130312 35.4952061,12.8130312 C34.4949454,12.8130312 33.4778264,12.3512748 32.8344003,11.5155807 L33.5593083,10.6827195 Z"
                id="Path"
              ></path>
              <polygon
                id="Path"
                points="41.2888509 0.121813031 42.4408365 0.121813031 42.4408365 3.50424929 44.289633 3.50424929 44.289633 4.52974504 42.4408365 4.52974504 42.4408365 12.5807365 41.2888509 12.5807365 41.2888509 4.52974504 39.6957391 4.52974504 39.6957391 3.50424929 41.2888509 3.50424929"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
