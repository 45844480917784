import { Component, Input } from '@angular/core'

@Component({
  selector: 'job-matching-chart',
  templateUrl: './job-matching-chart.component.html',
  styleUrls: ['./job-matching-chart.component.scss'],
})
export class CandidateJobMatchingComponent {
  @Input() job: number = 0
  @Input() core: number = 0
  @Input() coreFeature = false

  constructor() {}
}
