import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  _environment!: { version: string; env: string }

  // this will be replaced by actual hash post-build.js
  private currentHash = ''

  private versionSubject$ = new BehaviorSubject<boolean>(false)

  constructor(private http: HttpClient) {}

  watchVersion(): Observable<boolean> {
    return this.versionSubject$.asObservable()
  }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 15 minutes
   */
  public initVersionCheck(environment: { version: string; env: string }, url: string, frequency = 1000 * 60 * 15) {
    this._environment = environment

    this.currentHash = environment.version

    setInterval(() => {
      this.checkVersion(url)
    }, frequency)
    this.checkVersion(url)
  }
  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url: string) {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().getTime()).subscribe(
      (response: any) => {
        const hash = response[this._environment.env]

        const hashChanged = this.hasHashChanged(this.currentHash, hash)

        // If new version, do something
        if (hashChanged) {
          this.versionSubject$.next(true)
          // window.location.reload();
          // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
          // for an example: location.reload();
        }
        // store the new hash so we wouldn't trigger versionChange again
        // only necessary in case you did not force refresh
        this.currentHash = hash
      },
      err => {
        console.error(err, 'Could not get version')
      }
    )
  }
  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash: string, newHash: string) {
    if (!currentHash) {
      return false
    }

    return currentHash !== newHash
  }
}
