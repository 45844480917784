/** Responses the candidate gives to compliance questions */
export interface ICandidateComplianceResponses {
  gender: Gender
  ethnicity: Ethnicity
  militaryStatus: MilitaryStatus
  veteranStatus: VeteranStatus
  disabilityStatus: DisabilityStatus
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  NonBinary = 'NonBinary', // Gender non-conforming
  PreferNotToSay = 'PreferNotToSay',
}

export enum Ethnicity {
  NativeAmerican = 'NativeAmerican', // American Indian or Alaskan Native (Not Hispanic or Latino)
  Black = 'Black', // Black or African American (Not Hispanic or Latino
  White = 'White', // White (Not Hispanic or Latino)
  Asian = 'Asian', // Asian (Not Hispanic or Latino)
  Hispanic = 'Hispanic', // Hispanic or Latino
  PacificIslander = 'PacificIslander', // Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)
  TwoOrMore = 'TwoOrMore', // Two or More Races (Not Hispanic or Latino)
  PreferNotToSay = 'PreferNotToSay', // I prefer not to answer
}

export enum MilitaryStatus {
  None = 'None',
  Yes = 'Yes', // Yes, I am either currently serving, or have served, in the Armed Forces of the United States of America (including the Reserves and the National Guard)
  PreferNotToSay = 'PreferNotToSay',
}

export enum VeteranStatus {
  No = 'No',
  Yes = 'Yes', // Yes, I believe one or more of the categories apply to me
  PreferNotToSay = 'PreferNotToSay',
}

export enum DisabilityStatus {
  No = 'No',
  Yes = 'Yes', // Yes I have a Disability or Have a Record/History of Having a Disability
  PreferNotToSay = 'PreferNotToSay',
}
