import { Injectable } from '@angular/core'
import { SortByItem } from '@engineering11/angular-instantsearch/sort-by/sort-by'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root',
})
export class AlgoliaUtilService {
  constructor(private translate: TranslateService) {}

  sortByTransform() {
    // Had to use an any to make the typings work
    return <U extends SortByItem>(items: any[]): U[] => {
      return items.map((item: U) => {
        const translated = this.translate.instant(item.label) as string
        return {
          ...item,
          label: translated,
        }
      })
    }
  }
}
