import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-layout-admin',
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.scss'],
})
export class AppLayoutAdminComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
