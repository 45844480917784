import { IApplicationHistory } from '../model/application-history.model'

export const formatApplicationHistoryDateText = (historyItems: IApplicationHistory[]) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return historyItems.map(history => {
    const date = new Date(history.occurredAt)

    const occurredAt = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
    return {
      ...history,
      occurredAt,
    }
  })
}
