import { Component, EventEmitter, Input, Output } from '@angular/core'

import { IReference } from '../../model/interfaces'

@Component({
  selector: 'ui-reference-single-card-horizontal',
  template: ` <div class="item-card" (click)="viewDetail.emit(reference); editView.emit()">
    <div class="header">
      {{ reference.referenceType }}
      <span *ngIf="reference.default" class="fas fa-star float-right"></span>
    </div>
    <div class="body">
      <div>
        <div class="title text-accent-blue bold">{{ reference.firstName }} {{ reference.lastName }}</div>
        <div class="bold">{{ 'Company' | translate }}: {{ reference.companyName }}</div>
        <div class="spacer-2rem"></div>
        <div class="description">
          {{ 'Relationship' | translate }}:
          {{ reference.referenceRelationship | titlecase }}
        </div>
      </div>
    </div>
  </div>`,
})
export class UiReferenceSingleCardHorizontalComponent {
  @Input()
  reference!: IReference

  @Output()
  viewDetail = new EventEmitter()

  @Output()
  editView = new EventEmitter()
}
