import { IAppNotification } from '@engineering11/notifications-web'

export interface VirtualDialogueSubmittedNotificationForEmployer extends IAppNotification {
  type: 'employer_interview_response_received'
  virtualDialogueId: string
  jobPostId: string
  candidateName: string
  jobTitle: string
  candidateApplicationId: string
}

export interface RequestedUpdateFulfilledNotification extends IAppNotification {
  type: 'employer_application_updated'
  jobPostId: string
  candidateApplicationId: string
  jobTitle: string
  jobPostName: string
  candidateName: string
}

export interface IHiringTeamMemberNotification extends IAppNotification {
  type: 'employer_hiring_team_added'
  jobPostId: string
  candidateApplicationId: string
  jobTitle: string
}

export interface IEmployerJobPostClosedNotification extends IAppNotification {
  type: 'employer_job_post_closed'
  jobPostId: string
  jobTitle: string
  contentId: string
}

export interface IEmployerJobPostReactivatedNotification extends IAppNotification {
  type: 'employer_job_post_reactivated'
  jobPostId: string
  jobTitle: string
  contentId: string
}

export interface ICandidateAppliedNotification extends IAppNotification {
  type: 'employer_new_application_received'
  jobPostId: string
  candidateApplicationId: string
  jobTitle: string
}

export interface IApplicationReviewCreatedNotification extends IAppNotification {
  type: 'employer_new_review_available'
  jobPostId: string
  candidateApplicationId: string
  jobTitle: string
}

export interface IApplicationCommentCreatedNotification extends IAppNotification {
  type: 'employer_new_comments_available'
  jobPostId: string
  candidateApplicationId: string
  jobTitle: string
}

export interface UserMentionedNotification extends IAppNotification {
  type: 'messaging_user_mentioned'
  displayName: string
  conversationId: string
  messageId: string
}

export interface IEmployerApplicationResubmittedNotification extends IAppNotification {
  type: 'employer_application_resubmitted'
  jobTitle: string
  jobId: string
  candidateFirstName: string
  candidateLastName: string
  candidateApplicationId: string
}

export interface ICandidateApplicationResubmittedNotification extends IAppNotification {
  type: 'candidate_application_resubmitted'
  jobTitle: string
  jobId: string
}

export interface ISwimlaneStatusUpdatedNotification extends IAppNotification {
  type: 'employer_job_application_swimlane_changed'
  jobPostId: string
  candidateApplicationId: string
}

export type EmployerAppNotification =
  | VirtualDialogueSubmittedNotificationForEmployer
  | RequestedUpdateFulfilledNotification
  | IHiringTeamMemberNotification
  | IEmployerJobPostClosedNotification
  | IEmployerJobPostReactivatedNotification
  | ICandidateAppliedNotification
  | IApplicationReviewCreatedNotification
  | IApplicationCommentCreatedNotification
  | UserMentionedNotification
  | IEmployerApplicationResubmittedNotification
  | ICandidateApplicationResubmittedNotification
