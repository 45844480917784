import { Component } from '@angular/core'

@Component({
  selector: 'ui-maintenance-page',
  template: `
    <div class="e11-flex e11-justify-end e11-w-full e11-p-14">
      <img alt="Cnect Logo" src="/assets/images/maintenance-page/cnect-logo.png" width="200" />
    </div>
    <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-pl-6 e11-mb-12">
      <div class="md:e11-border-r-4 md:e11-border-skin-primary-accent md:e11-pr-6">
        <div class="e11-m-2">
          <div class="e11-relative e11-flex e11-flex-row e11-items-end">
            <div class="e11-flex e11-flex-row">
              <h3 class="e11-hidden md:e11-flex e11-mb-6 e11-pl-6 e11-font-bold e11-text-skin-primary-accent e11-text-2xl e11-w-44">
                {{ 'HOLD TIGHT!' | translate }}
              </h3>
            </div>

            <img src="/assets/images/maintenance-page/maintenance-gear.png" alt="Gear Icon" width="75%" class="e11-hidden md:e11-flex e11-pr-6" />
          </div>
          <div class="e11-flex e11-flex-col e11-pb-6 e11-pl-6 e11-text-skin-primary-accent e11-text-xl">
            <h1 class="e11-pb-2 e11-text-4xl">
              {{ 'Under Maintenance,' | translate }}
            </h1>
            <p class="e11-text-skin-primary-accent">
              {{ 'Paving the Way for Enhanced ' | translate }}<span class="e11-text-skin-secondary">{{ ' Cnectivity!' | translate }}</span>
              <br />
            </p>

            <!-- <p class="md:e11-hidden md:e11-pl-1 e11-pr-2 e11-text-skin-secondary">
            {{ ' Cnection  ' | translate
            }}<span class="e11-text-skin-primary-accent">{{
              'will resume shortly. In the meantime, please feel free to contact us!' | translate
            }}</span>
          </p> -->
          </div>
        </div>
      </div>

      <div class="e11-hidden md:e11-flex e11-pl-8 e11-flex-col e11-justify-center e11-items-center">
        <span class="e11-pb-8 e11-mb-8 e11-text-skin-secondary e11-text-3xl">
          {{ 'Cnection ' | translate }}
          <span class="e11-text-skin-primary-accent">{{ 'will resume shortly...' | translate }}</span>
        </span>

        <!-- <p class="e11-text-xl e11-text-skin-primary-accent e11-mb-2 e11-break-keep">
        {{ 'In the meantime, feel free to contact us!' | translate }}
      </p> -->
      </div>
    </div>

    <!-- <div class="e11-flex e11-w-full">
    <div class="e11-grow"></div>
    <div class="e11-w-48 e11-flex e11-flex-col e11-justify-start">
      <span class="e11-text-skin-primary-accent e11-font-bold">{{ 'EMAIL' | translate }}</span>
      <span class="e11-text-skin-primary-accent e11-text-sm">{{ 'info@cnect.com' | translate }}</span>
      <span class="e11-text-skin-primary-accent e11-font-bold">{{ 'WEB' | translate }}</span>
      <span class="e11-text-skin-primary-accent e11-text-sm">{{ 'cnect@cnected.com' | translate }}</span>
    </div>
  </div> -->
  `,
})
export class UiMaintenancePageComponent {
  constructor() {}
}
