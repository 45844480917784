import { Component, Input, Output, EventEmitter } from '@angular/core'

export type ImageSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

@Component({
  selector: 'profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss'],
})
export class ProfilePicComponent {
  _environment!: { version: string; env: string }

  showNewVersionWarning = false

  constructor() {}
  @Input() imageUrl?: string | null
  @Input() size: ImageSize = 'md'
  @Input() edit: boolean = false

  @Output() editProfilePic = new EventEmitter()

  editPic() {
    this.editProfilePic.emit(true)
  }
}
