import { Injectable } from '@angular/core'
import { IContentFile } from '@engineering11/content-web'
import { FilesService } from '@engineering11/files-web'

@Injectable({ providedIn: 'root' })
export class SignedUrlService {
  constructor(private filesService: FilesService) {}

  async applySignedUrls(contentFiles: IContentFile[]) {
    const applied = await this.filesService.signedURLs(contentFiles)
    contentFiles.forEach(cf => {
      const file = applied.find(a => {
        return a.id === cf.id
      })
      if (file) {
        cf.signedURL = file.signedURL
      }
    })
  }
}
