import { Injectable } from '@angular/core'
import { IConversationTypeConfig, BaseConversationConfigProvider } from '@engineering11/messaging-web'
import { ConversationTypes } from '../../constants'
import { ApplicationConversationConfigProvider } from './application-conversation-config.provider'

// Behaviour should be the same for both conversation types
@Injectable({ providedIn: 'root' })
export class ApplicationConversationDMConfigProvider extends ApplicationConversationConfigProvider {
  conversationType = ConversationTypes.JobApplicationDM
}
