import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { CollectionRepository } from '@engineering11/web-api-firebase'
import { IClientConfig } from '../model/client-config.model'

@Injectable({ providedIn: 'root' })
export class ClientConfigRepository extends CollectionRepository<IClientConfig> {
  constructor(afs: AngularFirestore) {
    super(afs)
  }
  protected COLLECTION = 'client_configuration/'

  get(key: string) {
    return super.get(key)
  }

  getAll() {
    return super.getAll()
  }

  getAllValueChanges() {
    return super._getAllValueChanges(this.COLLECTION)
  }
}
