import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'empty-avatar',
  templateUrl: './empty-avatar.component.html',
  styleUrls: ['./empty-avatar.component.scss'],
})
export class EmptyAvatarComponent implements OnInit {
  @Input() size?: string = 'small'
  nameDisplayed = ''

  private _name: string = ''

  @Input() set name(value: string) {
    this._name = value
    this.nameDisplayed = this.getDisplayNameFirstLetter()
  }

  get name(): string {
    return this._name
  }

  ngOnInit() {}

  getDisplayName() {
    return this.name.split(' ')[0]
  }

  getDisplayNameFirstLetter() {
    return this.name.slice(0, 1)
  }
}
