export interface IAsset {
  id: string
  category: AssetCategory // could be generically typed?
  type: string // could be generically typed?
  imageUrl: string
}

export enum AssetCategory {
  MilitaryAffiliation = 'MilitaryAffiliation',
}
