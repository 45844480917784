<h3 class="text-accent-blue nomargin">Deactivate Account</h3>
<p>Deactivate Account</p>

<e11-panel>
  <div class="row">
    <div class="col-xs-12 text-center">
      <e11-button
        id="submit_deactivate"
        value="{{ 'Deactivate Account' | translate }}"
        color="secondary"
        [type]="'button'"
        [buttonGroup]="true"
        (click)="modalDeactivation.open()"
      >
      </e11-button>
    </div>
  </div>
</e11-panel>

<!-- Deactivation modal -->
<e11-modal #modalDeactivation [width]="'sm'" [showHeader]="false" [closer]="true" [showFooter]="false">
  <div modal-body>
    <div class="row">
      <div class="col-xs-12 text-center">
        <div class="profile-pic"></div>
        <h3 class="text-accent-blue">
          {{ 'Are you sure you want to deactivate your cnect account?' | translate }}
        </h3>
        <p>
          {{
            'Even if you are happily settled in your job, you can always change your notification settings so that you receive weekly or monthly digests to stay in tune with the market around you. For instructions on changing your notification settings, visit cnected.com/faq/notifications.'
              | translate
          }}
        </p>
      </div>
    </div>

    <div class="spacer-2rem"></div>

    <div class="row">
      <div class="col-xs-12 text-center">
        <e11-button id="button_save" value="{{ 'Back' | translate }}" color="secondary" [type]="'button'" (click)="modalDeactivation.close()">
        </e11-button>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 text-center">
        <p class="pointer text-secondary underline">
          {{ 'I still want to deactivate my cnect account.' | translate }}
        </p>
      </div>
    </div>
  </div>
</e11-modal>
