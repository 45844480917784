/**
 * Copies the given data to the clipboard
 * @param toCopy
 */
export function copyToClipboard(toCopy: string) {
  try {
    navigator.clipboard.writeText(toCopy)
  } catch (error) {
    console.error(error)
    const selBox = document.createElement('textarea')
    selBox.style.position = 'fixed'
    selBox.style.left = '0'
    selBox.style.top = '0'
    selBox.style.opacity = '0'
    selBox.value = toCopy
    document.body.appendChild(selBox)
    selBox.focus()
    selBox.select()
    document.execCommand('copy')
    document.body.removeChild(selBox)
  }
}
