export interface DroppedItemData<T = any> {
  data: T
  previousColumn: {
    id: string | undefined
    title?: string | undefined
    index?: number
  }
  currentColumn: {
    id: string | undefined
    title?: string | undefined
    index?: number
  }
}

export interface ISwimlaneColumnConfig {
  id: string
  title: string
  active: boolean
  width?: number
}

export interface SwimlaneConfig {
  /** The width of all column in pixels */
  columnsWidth: number

  columns: ISwimlaneColumnConfig[]
}

export interface DraggedItem {
  column: {
    id: string
    title: string
  }
  data: any
}
