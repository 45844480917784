import { Component, Input, OnInit } from '@angular/core'
import { BrowserDetectionService } from '../..//service/browser-detection.service'
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector'
import { AuthService } from '@engineering11/auth-web'
import firebase from 'firebase/compat/app'
import { TokenStorage } from '../../service/token-storage.service'
import { IEnvironment } from '../../model/environment.model'

interface IUserVM {
  uid?: string
  displayName?: string | null
  email?: string | null
  emailVerified?: boolean
  tenantId?: string | null
  providerData?: any
  metadata?: any
}
@Component({
  selector: 'application-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
})
export class AboutComponent implements OnInit {
  @Input()
  environment?: IEnvironment

  isMobile?: boolean
  deviceInfo?: DeviceInfo
  userFromAuth?: IUserVM
  userFromStorage?: any
  appVersion?: string
  showAuthUser = false
  showStorageUser = false
  constructor(
    private deviceService: DeviceDetectorService,
    private browserDetectionService: BrowserDetectionService,
    private authService: AuthService,
    private tokenStorage: TokenStorage
  ) {}

  ngOnInit(): void {
    this.appVersion = this.environment?.version
    this.isMobile = this.browserDetectionService.getIsMobile()
    this.deviceInfo = this.deviceService.getDeviceInfo()
    this.authService.user$.subscribe((u: firebase.User) => {
      this.userFromAuth = this.buildUserVM(u)
    })
    this.userFromStorage = this.tokenStorage.getItem('user')
    this.userFromStorage.photoURL = undefined
  }

  toggleAuthUser() {
    this.showAuthUser = !this.showAuthUser
  }
  toggleStorageUser() {
    this.showStorageUser = !this.showStorageUser
  }

  buildUserVM(user: firebase.User): IUserVM {
    const userVM: IUserVM = {}

    userVM.uid = user.uid
    userVM.displayName = user.displayName
    userVM.email = user.email
    userVM.emailVerified = user.emailVerified
    userVM.tenantId = user.tenantId
    userVM.providerData = user.providerData
    userVM.metadata = user.metadata
    return userVM
  }
}
