import { Injectable } from '@angular/core'
import { IConversationTypeConfig, BaseConversationConfigProvider } from '@engineering11/messaging-web'
import { ConversationTypes } from '../../constants'

@Injectable({ providedIn: 'root' })
export class CandidatePoolDmConfigProvider extends BaseConversationConfigProvider {
  conversationType = ConversationTypes.CandidatePoolDM

  getConfig(): IConversationTypeConfig {
    return {
      canEditOwnMessages: true,
      canDeleteOwnMessages: false,
      canReactOnMessages: true,
      canDeleteConversation: false,
      maxMinutesToEditOwnMessages: 15,
      numberOfEditOwnMessages: 5,
    }
  }
}
