import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'ui-skill-count',
  templateUrl: './skill-count.component.html',
  styleUrls: ['./skill-count.component.scss'],
})
export class UiSkillCountComponent implements OnInit {
  @Input() count: number = 0
  constructor() {}

  ngOnInit(): void {}
}
