import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { CollectionRepository, FirestoreQueryBuilder } from '@engineering11/web-api-firebase'
import { AssetCategory, IAsset } from '../model/asset.model'

@Injectable({ providedIn: 'root' })
export class AssetRepository extends CollectionRepository<IAsset> {
  constructor(afs: AngularFirestore) {
    super(afs)
  }
  protected COLLECTION = 'asset/'

  getAllByAssetCategory(category: AssetCategory) {
    const query = new FirestoreQueryBuilder().where('category', '==', category).build()
    return super.query(query)
  }
}
