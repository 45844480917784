import { Component, ElementRef, ViewChild } from '@angular/core'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'

// !This component is functionally incomplete and does not deactivate the account

@Component({
  selector: 'account-deactivate-account',
  templateUrl: './deactivate-account.component.html',
  styleUrls: ['./deactivate-account.component.scss'],
})
export class AccountDeactivateAccountComponent {
  @ViewChild('modalLogin') modalLogin!: ElementRef<any>

  constructor(private notificationsService: E11NotificationsService) {}

  activateNotification(type: string, message: string): void {
    // const notification = new E11NotificationMessage(message, type, false, 'Close')
    // this.notificationsService.popNotificationMessage(notification)
  }
}

// const notification: E11NotificationMessage = {
//   title: 'An Error Occurred',
//   message: `An error occurred and we were unable to process your registration.`,
//   type: 'error',
//   autoClose: false,
//   dismissOnRouteChange: true,
// }
