import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core'

@Directive({
  selector: '[onVisible]',
})
export class OnVisibleDirective implements AfterViewInit, OnDestroy {
  @Input() options?: IntersectionObserverInit
  @Output() onVisible: EventEmitter<any> = new EventEmitter()

  private intersectionObserver?: IntersectionObserver

  constructor(private element: ElementRef) {}

  public ngAfterViewInit() {
    this.intersectionObserver = new IntersectionObserver(entries => {
      this.checkForIntersection(entries)
    }, this.options)

    this.intersectionObserver.observe(this.element.nativeElement)
  }

  public ngOnDestroy() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect()
    }
  }

  private checkForIntersection(entries: Array<IntersectionObserverEntry>) {
    entries.forEach((entry: IntersectionObserverEntry) => {
      const isIntersecting = entry.isIntersecting && entry.target === this.element.nativeElement

      if (isIntersecting) {
        this.onVisible.emit()
      }
    })
  }
}
