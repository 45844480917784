import { AfterViewInit, Component, Input } from '@angular/core'
import { VersionCheckService } from '../../service/version-check.service'

@Component({
  selector: 'lib-version-checker',
  templateUrl: './version-checker.component.html',
  styleUrls: ['./version-checker.component.scss'],
})
export class VersionCheckerComponent implements AfterViewInit {
  _environment!: { version: string; env: string }

  showNewVersionWarning = false

  constructor(private versionCheckService: VersionCheckService) {}

  @Input()
  set environment(value: { version: string; env: string }) {
    this._environment = value
  }

  ngAfterViewInit(): void {
    this.versionCheckService.initVersionCheck(this._environment, '/assets/js/version.json')
    this.versionCheckService.watchVersion().subscribe(newVersion => {
      this.showNewVersionWarning = newVersion
    })
  }

  reload() {
    window.location.reload()
  }
}
