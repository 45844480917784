import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { IHiringTeamMemberNotification } from '../../model/app-notification.model'

@Injectable({ providedIn: 'root' })
export class HiringTeamMemberAddNotificationHandler implements INotificationHandler<IHiringTeamMemberNotification, INotificationView> {
  notificationType = 'employer_hiring_team_added'
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}

  async onClick(notification: IHiringTeamMemberNotification): Promise<boolean> {
    if (!notification.viewed) {
      await this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.router.navigate(['/jobs/post', notification.jobPostId])
  }
}
