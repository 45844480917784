import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class LayoutNavigationService {
  // sidebar on/off switch - defaults to on/true
  layoutNavigationStatusSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  layoutNavigationStatusObservable$

  constructor() {
    this.layoutNavigationStatusObservable$ = this.layoutNavigationStatusSource.asObservable()
  }

  layoutNavigationToggle(status: boolean) {
    this.layoutNavigationStatusSource.next(status)
  }
}
