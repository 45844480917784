<div class="layout-app-admin">
  <div class="left e11-border-r e11-border-skin-app-borders">
    <!-- <app-nav-left></app-nav-left> -->
    <!-- <div class="layout-nav-left e11-border-r e11-border-skin-app-borders hover:e11-border-skin-primary-accent e11-bg-skin-app-bg">
            <app-nav-left></app-nav-left>
        </div> -->
  </div>
  <div class="right">
    <div class="layout-content">
      <app-header></app-header>
      <div class="layout-router-outlet">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
