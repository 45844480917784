import { Component, EventEmitter, Input, Output } from '@angular/core'

import { IWorkHistory } from './../../model/interfaces'

@Component({
  selector: 'ui-work-history-single-card-vertical',
  template: `
    <div style="e11-flex">
      <div class="e11-w-full" (click)="viewDetail.emit(workHistory)">
        <h4 class="e11-float-left e11-mr-2 e11-text-skin-primary-accent">{{ workHistory.companyName }}</h4>
        <a
          class="e11-flex e11-items-center e11-justify-center e11-cursor-pointer e11-float-left"
          (click)="viewDetail.emit(workHistory); editView.emit(true)"
        >
          <span class="material-icons-outlined e11-text-skin-light hover:e11-text-skin-primary e11-h-8 e11-w-8"> edit </span>
        </a>
        <a class="e11-flex e11-items-center e11-justify-center e11-cursor-pointer e11-float-left" (click)="deleteItem.emit(workHistory)">
          <span class="material-icons-outlined e11-text-skin-light hover:e11-text-skin-secondary e11-h-8 e11-w-8"> delete </span>
        </a>
        <div class="e11-clear-both"></div>
        <p>{{ workHistory.jobTitle }}</p>
        <date-display-start-end class="detail-line-item" [item]="workHistory"></date-display-start-end>
      </div>
    </div>
  `,
  styleUrls: ['./ui-work-history-vertical.component.scss'],
})
export class UiWorkHistorySingleCardVerticalComponent {
  @Input()
  workHistory!: IWorkHistory

  @Output()
  viewDetail = new EventEmitter<IWorkHistory>()

  @Output()
  editView = new EventEmitter()

  @Output()
  deleteItem = new EventEmitter<IWorkHistory>()
}
