import { Pipe, PipeTransform } from '@angular/core'
import { IAddress } from '../model/interfaces'
import { formSelectOptionsCountry } from '@engineering11/web-utilities'

@Pipe({ name: 'addressFormat' })
export class AddressFormatPipe implements PipeTransform {
  transform(address?: IAddress): string | undefined {
    if (!address) return address
    const city = address.city && address.state ? `${address.city}, ` : address.city ?? ''

    return `${city}${address.state ?? ''} ${formSelectOptionsCountry.find(country => country.value === address.countryCode)?.name ?? ''}`
  }
}
