import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'
import { IEducation } from '../../model/interfaces'

@Component({
  selector: 'ui-education-single-card-vertical',
  template: `
    <div style="e11-flex">
      <div class="e11-w-full" (click)="viewDetail.emit(education)">
        <h4 class="e11-float-left e11-mr-2 e11-text-skin-primary-accent">{{ education.schoolName }}</h4>
        <a
          class="e11-flex e11-items-center e11-justify-center e11-cursor-pointer e11-float-left"
          (click)="viewDetail.emit(education); editView.emit(true)"
        >
          <span class="material-icons-outlined e11-text-skin-light hover:e11-text-skin-primary e11-h-8 e11-w-8"> edit </span>
        </a>
        <a class="e11-flex e11-items-center e11-justify-center e11-cursor-pointer e11-float-left" (click)="deleteItem.emit(education)">
          <span class="material-icons-outlined e11-text-skin-light hover:e11-text-skin-secondary e11-h-8 e11-w-8"> delete </span>
        </a>
        <div class="e11-clear-both"></div>
        <p class="detail-line-item level">{{ education.degreeLevel }}</p>
        <p class="detail-line-item title">
          {{ education.degreeTitle }}
          <span class="e11-text-skin-light e11-text-sm" *ngIf="education.notCompleted"> *{{ 'Not yet Completed' | translate }}</span>
        </p>

        <date-display-start-end class="detail-line-item" [item]="education"></date-display-start-end>
      </div>
    </div>
  `,
  styleUrls: ['./ui-education-single-card-vertical.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiEducationSingleCardVerticalComponent {
  @Input()
  education!: IEducation

  @Output()
  viewDetail = new EventEmitter()

  @Output()
  editView = new EventEmitter()

  @Output()
  deleteItem = new EventEmitter<IEducation>()
}
