import { Component } from '@angular/core'

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss'],
})
export class CopyrightComponent {
  yearCurrent = new Date().getFullYear()

  constructor() {}
}
